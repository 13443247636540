<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-size="headerSize"
      :header-position="headerPosition"
      :header-link="headerLink"
      :gallery="gallery"
      :lang="currentLocale"
      class="t3-ce-image"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useCurrentLocale } from '#imports'

import type { T3CeGalleryProps } from '~shared/types'

const props = withDefaults(defineProps<T3CeGalleryProps>(), {
  uid: 0,
  index: 0,
  appearance: () => ({
    frameClass: '',
    layout: '',
    spaceAfter: '',
    spaceBefore: '',
    maskPosition: 'none'
  }),
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: ''
})

const PopupImage = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeImage/PopupImage')
)
const ImageWithMask = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeImage/ImageWithMask')
)

const currentLocale = useCurrentLocale()

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    case 'popupImage':
      return PopupImage
    default:
      return ImageWithMask
  }
})
</script>
